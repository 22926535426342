import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "F:/web/active/infoamazonia-mercurio/src/components/reports/report-layout.js";
import Image from "!url-loader!../../images/fotos/GP1SU06V_High_res.jpg";
export const meta = {
  name: "brazil",
  title: "Nueva fiebre del oro activa mercados ilícitos de mercurio en Roraima",
  intro: "La economía más grande e industrializada de Sudamérica, Brasil, no produce mercurio. Pero sus florecientes minas de oro ilegales no pueden funcionar sin la sustancia tóxica.",
  by: "por Sam Cowie (texto). Videos por Rafael Norton, Luiz Felipe Saleh e Erik Von Poser.",
  image: Image,
  next: {
    slug: "guyana",
    title: "El metal tóxico a la sombra de la industria del oro",
    description: "A pesar de su riesgo para la salud y el medio ambiente, el mercurio se utiliza ampliamente en la minería del oro, y para los mineros de la Amazonía, el líquido plateado es casi tan valioso como el propio oro."
  }
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Sidenote = makeShortcode("Sidenote");
const layoutProps = {
  meta,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`En Roraima, el estado más`}</strong>{` septentrional de Brasil, no hay ni una sola mina de oro artesanal `}<a parentName="p" {...{
        "href": "https://www.bbc.com/portuguese/internacional-48534473"
      }}>{`legalizada`}</a>{`, sin embargo, la estatua de sete metros de alto del `}<a parentName="p" {...{
        "href": "https://g1.globo.com/rr/roraima/noticia/2020/06/08/monumento-ao-garimpeiro-e-alvo-de-pichacao-em-boa-vista.ghtml"
      }}>{`Monumento a los Mineros`}</a>{`, ubicada en el centro de su tranquila capital, Boa Vista, no deja casi lugar dudas sobre de qué lado están muchas lealtades locales.`}</p>
    <p>{`Cada día, flotas de automóviles, camiones, `}<a parentName="p" {...{
        "href": "https://g1.globo.com/rr/roraima/noticia/2020/01/09/exercito-prende-15-garimpeiros-em-barcos-carregados-de-material-para-garimpo-na-terra-yanomami.ghtml"
      }}>{`barcos`}</a>{`, `}<a parentName="p" {...{
        "href": "https://g1.globo.com/rr/roraima/noticia/2020/07/25/aviao-e-apreendido-empista-clandestina-em-regiao-de-garimpo-ilegal-no-interior-de-roraima.ghtml"
      }}>{`avionetas`}</a>{` e `}<a parentName="p" {...{
        "href": "https://folhabv.com.br/noticia/POLICIA/Ocorrencias/Helicoptero-que-era-usado-no-garimpo-e-apreendido-no-Paredao/65710"
      }}>{`incluso helicópteros`}</a>{` transportan hombres con insumos para minería y oro que van y vienen de cientos de áreas de explotación minera ilegales en reservas indígenas y ríos en este estado amazónico poco poblado. Algunos de los vehículos transportan contenedores de mercurio, la sustancia tóxica plateada comúnmente utilizada por los mineros para procesar el oro.`}</p>
    <Video url="https://www.youtube.com/embed/7iIYvI604ac" mdxType="Video" />
    <p>{`En una madrugada reciente, a finales de junio, la policía militar detuvo un auto con hombres rumbo a Boa Vista, quienes regresaban de un pozo minero ilegal en el distrito de Alto Alegre, donde se encuentra parte de la vasta reserva indígena de los yanomamis en Brasil. Según se informó, días antes, mineros de la región habían disparado y matado a dos Yanomami y herido a otros.`}</p>
    <p>{`Al registrar el automóvil, los oficiales encontraron ocho balas, un juego de balanzas digitales y dos botellas de Coca-Cola pequeñas que contenían 10 kg de mercurio; una incautación en carretera relativamente grande de la sustancia, pero solo una fracción de lo que se utiliza en el estado anualmente.`}</p>
    <p>{`La economía más grande e industrializada de Sudamérica, Brasil, no produce mercurio y, aún antes de 2017, `}<a parentName="p" {...{
        "href": "http://www.mercuryconvention.org/DNNAdmin/AllENGLISHNewsEntry/tabid/3444/articleType/ArticleView/articleId/158175/language/en-US/Brazil-brings-to-74-the-number-of-future-Parties-to-the-Minamata-Convention.aspx"
      }}>{`cuando el país ratificó el Convenio de Minamata`}</a>{`, tratado internacional para proteger la salud humana de las emisiones de mercurio, ya se había prohibido  oficialmente su venta y las agencias federales mantenían `}<a parentName="p" {...{
        "href": "https://www.icmbio.gov.br/cepsul/images/stories/legislacao/Instrucao_normativa/2015/in_ibama_08_2015_estabelece_cadastro_tecnico_federal_atividades_poluidoras__rvg_p_ibama_395_1989_p_435_1989_p_32_1995_p_46_1996.pdf"
      }}>{`controles estrictos`}</a>{` sobre las importaciones registradas.`}</p>
    <p>{`Sin embargo, en los años recientes ha habido un notable aumento de la minería ilegal en Roraima, particularmente en la `}<a parentName="p" {...{
        "href": "https://news.mongabay.com/2019/07/yanomami-amazon-reserve-invaded-by-20000-miners-bolsonarofails-to-act/"
      }}>{`reserva indígena de los yanomamis`}</a>{` y, en vista de que casi todos los mineros artesanales de oro del Amazonas brasileño utilizan mercurio, según estudios realizados por el gobierno, es prácticamente seguro que esta nueva fiebre del oro ha causado un aumento del uso ilícito de mercurio.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Es prácticamente seguro que esta nueva fiebre del oro ha causado un aumento del uso ilícito de mercurio`}</p>
    </blockquote>
    <Video url="https://www.youtube.com/embed/s6wbj5-VR8w" mdxType="Video" />
    <p>{`El mercurio utilizado en miles de garimpos (minas ilegales) en todo la Amazonía brasileña suele ser introducido de contrabando desde países vecinos o importado legalmente para utilizarlo en la producción industrial del país y luego es canalizado a redes clandestinas.`}</p>
    <Sidenote side="right" mdxType="Sidenote">
	Estudio de 2018 del Inventario Nacional de Emisiones de Mercurio de Brasil el precio era de BRL 600 a BRL 1200 el kilo
    </Sidenote>
    <p>{`El informe de 2020 de la Fiscalía Federal de Brasil, titulado “Minería ilegal de oro en la Amazonía: marcos jurídicos y asuntos polémicos”, destaca la importancia de las “medidas de mando y control de las fronteras y los mercados informales de comercio de mercurio” para que Brasil cumpla sus objetivos en el marco del Convenio de Minamata.`}</p>
    <p>{`“Necesitamos inspecciones más permanentes y estructuradas en el lado brasileño”, dijo la diputada de Roraima, Joênia Wapixana, la única legisladora indígena de Brasil. “Pero también necesitamos medidas más estrictas por parte de los países productores de mercurio”, añadió.`}</p>
    <h2>{`Garimpo en Roraima`}</h2>
    <p>{`Hoy en día, se cree que miles de garimpeiros están excavando en busca de oro en las reservas indígenas de Roraima, ricas en minerales, destruyendo los hábitats naturales y poniendo en peligro a las poblaciones locales, con la crisis de COVID-19 en aumento. Las operaciones recurrentes realizadas por la Policía Federal de Brasil, los organismos de control ambiental y las fuerzas armadas, con el objeto de hacerle frente a la minería ilegal en años recientes han dado lugar a múltiples detenciones, significativas incautaciones de oro y otros bienes, pero, a la larga, no han logrado detener el flujo.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“La mayor parte de la economía del estado sigue funcionando a partir de la minería ilegal, por lo que la presión es enorme”, dijo una fuente.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGNqqIlNAT/xAAbEAACAwADAAAAAAAAAAAAAAABAgADERIhI//aAAgBAQABBQLjjGsNDUJV6QdOmMP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAQUAAAAAAAAAAAAAAAAAAREQISIxUf/aAAgBAQAGPwLKmy7I4S0f/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFR8P/aAAgBAQABPyEqVqaxyoFpqwuGzkB8DB7xP//aAAwDAQACAAMAAAAQ5N//xAAYEQACAwAAAAAAAAAAAAAAAAAAARExUf/aAAgBAwEBPxBUSsP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAASH/2gAIAQIBAT8QrX//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFh0f/aAAgBAQABPxBtD9MZwEIb78jFRciRK3GGrq4jrg5GqV3KSbsG9DP/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/a18989589fb695699de7e5d3d936fcd1/e93cc/GP1SU06I_High_res.webp 300w", "/static/a18989589fb695699de7e5d3d936fcd1/b0544/GP1SU06I_High_res.webp 600w", "/static/a18989589fb695699de7e5d3d936fcd1/68fc1/GP1SU06I_High_res.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/a18989589fb695699de7e5d3d936fcd1/73b64/GP1SU06I_High_res.jpg 300w", "/static/a18989589fb695699de7e5d3d936fcd1/3ad8d/GP1SU06I_High_res.jpg 600w", "/static/a18989589fb695699de7e5d3d936fcd1/6c738/GP1SU06I_High_res.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/a18989589fb695699de7e5d3d936fcd1/6c738/GP1SU06I_High_res.jpg",
            "alt": "Garimpeiros están excavando en busca de oro en las reservas indígenas de Roraima, ricas en minerales.",
            "title": "Garimpeiros están excavando en busca de oro en las reservas indígenas de Roraima, ricas en minerales.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Garimpeiros están excavando en busca de oro en las reservas indígenas de Roraima, ricas en minerales. `}<strong parentName="em">{`Foto por Greenpeace`}</strong>{` `}</em></p>
    <p>{`Los propios mineros son en su mayoría hombres y, a veces, mujeres, pobres, a menudo explotados, que se encuentran en la base de una lucrativa pirámide de contrabando de oro, comercio y logística ilegales que mueven miles de millones de dólares anualmente por todo Brasil.`}</p>
    <p>{`Es imposible obtener cifras exactas debido al carácter ilícito del comercio, pero se estima que cada año, miles de kilos de oro, incluyendo, más recientemente, las cantidades en aumento de la vecina Venezuela, se extraen ilegalmente y se trafican desde Roraima, robando al estado los tan necesarios ingresos tributarios.`}</p>
    <p>{`El proceso es posible debido a la pobreza del estado, sus fronteras permeables y sus fuerzas de orden público agobiadas, pero también debido a funcionarios locales corruptos y empresarios e inversores sin escrúpulos.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Hay una enorme tolerancia social en la sociedad de Roraima; al minero salvaje no se le considera un criminal”, afirmó Alisson Marugal, fiscal federal de Roraima.`}</p>
    </blockquote>
    <p>{`Es un riesgo que compensa, al menos para algunos. Los precios mundiales del oro están en niveles récord, alrededor de USD 62,000 por kilo (en 4 de septiembre), a la vez que los organismos de protección ambiental y de poblaciones indígenas de Brasil se han visto `}<a parentName="p" {...{
        "href": "https://www.reuters.com/article/us-health-coronavirus-brazil-environment/exclusive-brazil-scales-back-environmental-enforcement-amid-coronavirus-idUSKBN21E15H"
      }}>{`perjudicados por la crisis de COVID-19`}</a>{` y la `}<a parentName="p" {...{
        "href": "https://www.reuters.com/article/us-brazil-environment/televised-raid-on-illegal-amazonmining-angers-brazil-government-leads-to-sacking-idUSKCN21W31A"
      }}>{`interferencia`}</a>{` del gobierno del Presidente Jair Bolsonaro.`}</p>
    <p>{`El líder de extrema derecha fue elegido presidente en 2018 y obtuvo una victoria contundente aquí en Roraima después de una campaña colmada de retórica pro-garimpeiros, que incluía promesas de aflojar las regulaciones ambientales y legalizar la minería en las reservas indígenas.`}</p>
    <Video url="https://www.youtube.com/embed/Vqef9JdC1D0" mdxType="Video" />
    <p>{`“Si yo fuera el rey de Roraima, este estado sería en 20 años tan rico como Japón”, dijo Bolsonaro durante una `}<a parentName="p" {...{
        "href": "https://folhabv.com.br/noticia/POLITICA/Roraima/Bolsonaro---Se-eu-fosse-rei-de-Roraima--estado-teria-economia-igual-a-do-Japao-/38719"
      }}>{`visita realizada en 2018`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Durante décadas, una multitud de mineros ilegales de todo Brasil han acudido en masa a este El Dorado amazónico con la esperanza de encontrar fortuna`}</p>
    </blockquote>
    <p>{`Durante décadas, una multitud de mineros ilegales de todo Brasil han acudido en masa a este El Dorado amazónico con la esperanza de encontrar fortuna. En la década de 1980, unos cuarenta mil garimpeiros invadieron la tierra yanomami, que es más grande que Portugal.`}</p>
    <p>{`Como resultado, entre 15% y 20% de la tribu murió por enfermedades y violencia, según lo indicado por antropólogos. Los mineros fueron finalmente expulsados en 1992, cuando la reserva fue oficialmente demarcada por el gobierno brasileño, pero siempre regresaban.`}</p>
    <p>{`En julio del año en curso, la Policía Federal arrestó en Boa Vista a Pedro Emiliano García, de 59 años, quien portaba 2 kg de oro. Es el único brasileño vivo acusado de genocidio, tras una masacre en 1993 en la tierra de los yanomamis; un infame `}<a parentName="p" {...{
        "href": "https://folhabv.com.br/noticia/CIDADES/Capital/Condenadopor-genocidio-de-indios-recebeu-auxilio-emergencial/67085"
      }}>{`ex minero de oro`}</a>{`, `}<a parentName="p" {...{
        "href": "http://www.pf.gov.br/imprensa/noticias/2020/07-noticias-de-julho-de-2020/pf-prende-suspeito-de-atuar-na-logistica-do-garimpo-em-terra-indigena-ianomami"
      }}>{`acusado de operar aviones`}</a>{` que transportaban mineros a la reserva y de ser el “propietario” de un pozo minero en ese lugar.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "746px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABA//EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAVnYIe9kn//EABgQAQEBAQEAAAAAAAAAAAAAAAIBAwQy/9oACAEBAAEFAtbZLuyn0wKeerRh4ZjQf//EABYRAQEBAAAAAAAAAAAAAAAAAAARQf/aAAgBAwEBPwHEf//EABYRAQEBAAAAAAAAAAAAAAAAAAASQf/aAAgBAgEBPwHVP//EABoQAAIDAQEAAAAAAAAAAAAAAAARAQISITH/2gAIAQEABj8C56UrlsypIK5sjV4cs//EABoQAQEBAAMBAAAAAAAAAAAAAAEAEUFRYXH/2gAIAQEAAT8h1L+L1eZimTqWt7AjHOJ2V1rf/9oADAMBAAIAAwAAABDj3//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EGH/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxAYf//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExQVFxkbH/2gAIAQEAAT8QIK6DTBBoXRUb4qYHRqxPsRal5V4lpKGlhzL2Zyovqf/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/62786a741109ea817671bd630b79ca09/e93cc/foto-policia-federal.webp 300w", "/static/62786a741109ea817671bd630b79ca09/b0544/foto-policia-federal.webp 600w", "/static/62786a741109ea817671bd630b79ca09/16dd7/foto-policia-federal.webp 746w"],
            "sizes": "(max-width: 746px) 100vw, 746px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/62786a741109ea817671bd630b79ca09/73b64/foto-policia-federal.jpg 300w", "/static/62786a741109ea817671bd630b79ca09/3ad8d/foto-policia-federal.jpg 600w", "/static/62786a741109ea817671bd630b79ca09/2ca71/foto-policia-federal.jpg 746w"],
            "sizes": "(max-width: 746px) 100vw, 746px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/62786a741109ea817671bd630b79ca09/2ca71/foto-policia-federal.jpg",
            "alt": "En julio del año en curso, la Policía Federal arrestó en Boa Vista a Pedro Emiliano García, de 59 años, quien portaba 2 kg de oro. Es el único brasileño vivo acusado de genocidio.",
            "title": "En julio del año en curso, la Policía Federal arrestó en Boa Vista a Pedro Emiliano García, de 59 años, quien portaba 2 kg de oro. Es el único brasileño vivo acusado de genocidio.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`En julio del año en curso, la Policía Federal arrestó en Boa Vista a Pedro Emiliano García, de 59 años, quien portaba 2 kg de oro. Es el único brasileño vivo acusado de genocidio.`}</em></p>
    <p>{`Aislado del resto del país, la única otra capital de estado brasileño a la que se puede acceder por carretera desde Roraima es Manaos, la capital de la metrópoli de la selva del estado de Amazonas, a la que solo se puede acceder por avión, por río en un largo viaje en barco o por tierra a lo largo de los 870 km de la carretera BR-319 sin asfaltar, que puede ser prácticamente imposible de cruzar durante la temporada de lluvias, de noviembre a marzo.`}</p>
    <p>{`No obstante, Roraima comparte fronteras terrestres porosas con Venezuela y Guyana. Venezuela se está recuperando de su propia fiebre del oro y de una crisis humanitaria sin precedentes con miles de desesperados refugiados venezolanos que se han asentado en el vecino estado brasileño en los últimos años.`}</p>
    <p>{`También ha habido un repunte en el flujo de contrabando que incluye oro, armas, cocaína y mercurio con venezolanos explotados, a menudo obligados a trabajar para grupos delictivos, afirman los expertos locales en seguridad.`}</p>
    <p>{`Mientras tanto, Guyana, uno de los países más pobres de Sudamérica, con una población de 780.000 habitantes y que recientemente comenzó a perforar enormes reservas de petróleo en alta mar, sigue importando sustanciales cantidades de mercurio – como mínimo `}<a parentName="p" {...{
        "href": "https://www.stabroeknews.com/2019/08/31/news/guyana/mercury-imports-capped-at-1000-flasks-per-year/"
      }}>{`34,5 toneladas`}</a>{` en 2019 - para utilizarlo en sus propias operaciones de minería artesanal de oro.
Parte del mercurio se trafica a Roraima, según múltiples fuentes locales informaron a InfoAmazonia, la que denominó a Guyana como la principal fuente de mercurio ilícito en el estado.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Para los brasileños el acceso a este mercurio (de Guyana) es muy fácil”, afirmó un experto en minería ilegal de Roraima.`}</p>
    </blockquote>
    <h2>{`Fácil acceso`}</h2>
    <p>{`La frontera de Roraima con Guyana está formada por el río Takutu; en muchos puntos tiene menos de 100 metros de ancho, tiene cruces ilegales y durante la estación seca, incluso es posible vadearlo.`}</p>
    <p>{`El río es una ruta consolidada entre ambos países para el tráfico de personas y contrabando de artículos como bebidas alcohólicas, productos alimenticios, suministros médicos y, según afirman fuentes, mercurio.`}</p>
    <p>{`“No es necesario que vengan por la carretera; vienen por el río y traen la cantidad que quieren. Es muy fácil de comprar”, aseveró una fuente.`}</p>
    <p>{`“La región, debido a sus características inhóspitas, es una gran facilitadora de cualquier transporte ilícito”, dijo una fuente policial.`}</p>
    <p>{`Según una fuente de la Policía Federal de Carreteras de Brasil, responsable de las incautaciones en carreteras, parte del mercurio se pasa de contrabando en taxis a través de la frontera con Guyana y se lleva a Boa Vista. Esta versión también fue corroborada por fuentes tomadas en cuenta para este informe en Georgetown, capital de Guyana. La densidad de la sustancia también hace que sea un producto fácil de contrabandear.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Una botella de Coca-Cola de 2 litros tiene capacidad para 20 kg de mercurio”`}</p>
    </blockquote>
    <p>{`“Una botella de Coca-Cola de 2 litros tiene capacidad para 20 kg de mercurio”, dijo una fuente. “Es poco probable que alguna vez encuentre una tonelada de mercurio; esto es lo que dificulta las inspecciones”.`}</p>
    <p>{`Además de la densidad, el tráfico de mercurio se ve favorecido por la relativa ambigüedad legal de la sustancia. A diferencia de la cocaína, la marihuana o el oro, por ejemplo, a pesar de sus cualidades destructivas, muchas personas no son conscientes de que es ilegal, incluso muchos policías, afirmaron algunos.`}</p>
    <p>{`“La mayoría de la gente no lo considera un delito”, dijo un agente de la policía federal que antes trabajaba en Guyana.`}</p>
    <p>{`Varias otras fuentes sugirieron que se trajeron pequeñas cantidades de mercurio de Venezuela, sobre todo a través del río Uraricoera, en pequeñas porciones a la vez`}</p>
    <p><strong parentName="p">{`Territorio Yanomami y los puntos de minería ilegal`}</strong></p>
    <iframe src="https://infoamazonia.org/en/embed/?map_only=1&map_id=59813&width=960&height=480" width="960" height="480" frameBorder="0"></iframe>
    <p>{`“Es el llamado trabajo de hormiga, donde cada una lleva de 0,5 L a 1 L”, dijo una fuente policial.`}</p>
    <p>{`Otra fuente, con conexiones mineras ilegales, dijo que los aviones y helicópteros de Roraima que transportan garimpeiros y aterrizan en el lado venezolano de la frontera, a veces recogen el mercurio y lo distribuyen en el territorio yanomami.`}</p>
    <p>{`Incluso hay algunas pruebas que sugieren que el mercurio contrabandeado a Roraima también se lleva a otras regiones de Brasil.
El año pasado, la policía de carreteras del Amazonas, el estado vecino de Roraima, arrestó a un hombre con 150 kg de mercurio en cuatro frascos de metal, aparentemente valorados en BRL 90.000, junto con 35 mil paquetes de cigarrillos.`}</p>
    <p>{`El hombre dijo que había salido de Boa Vista y estaba transportando el mercurio a Manaos, que luego sería enviado a Itaituba, el centro minero ilegal más importante del Amazonas brasileño.`}</p>
    <Video url="https://www.youtube.com/embed/6mVE6V9aaWs" mdxType="Video" />
    <p>{`En el resto de Brasil, se cree que una gran cantidad de mercurio entra a través de Bolivia, el segundo mayor importador del mundo después de la India, que, según las cifras del Banco Mundial, importó 196 toneladas en 2018.`}</p>
    <p>{`Los estados de Rondonia y Mato Grosso, los cuales comparten enormes fronteras terrestres con Bolivia, tienen sus propias industrias mineras ilegales, así como acceso por carretera a gran parte del Amazonas brasileño.`}</p>
    <p>{`A principios de este año, dos hombres fueron arrestados en la carretera BR-364 en Rondonia, transportando `}<a parentName="p" {...{
        "href": "https://g1.globo.com/ro/rondonia/noticia/2020/01/25/80-quilos-de-mercurio-sao-apreendidos-com-dupla-em-carro-na-br-364.ghtml"
      }}>{`80 kg de mercurio`}</a>{` que, según dijeron, se vendería en los pozos mineros ilegales de la ciudad de Ariquemes.`}</p>
    <p>{`La cantidad de rutas potenciales demuestra que el mercurio es una sustancia ágil que se puede adaptar a sus condiciones, incluyendo restricciones, incautaciones y alzas de precios. Como muchas sustancias ilegales, eventualmente llega a donde hay demanda.`}</p>
    <p>{`“Es como la misma lógica del tráfico de drogas”, dijo un experto forense de la Policía Federal con sede en la región del río Tapajós en Brasil, quien prefirió mantener el anonimato. “Las cantidades más grandes se llevan a destinos y luego se dividen en cantidades más pequeñas para venderlas”.`}</p>
    <p>{`En todo el Amazonas brasileño, los productos que se venden en minas remotas ilegales, a las que muchas veces solo se puede llegar en avión o en barco, tales como agua, Coca-Cola, alimentos enlatados, alcohol y acceso a Internet, se ofrecen a precios muy elevados y se dice que con el mercurio pasa lo mismo.`}</p>
    <p>{`Los precios del mercurio en Brasil parecen fluctuar según la región. Una fuente aseveró que el mercurio se podría comprar localmente en Roraima por BRL 500 el kilo, mientras que en el Estudio de 2018 del Inventario Nacional de Emisiones de Mercurio de Brasil el precio era de BRL 600 a BRL 1200 el kilo, dependiendo del estado.`}</p>
    <p>{`El mercurio también parece estar ampliamente disponible `}<a parentName="p" {...{
        "href": "https://www.construvolts.com.br/mercrio-vivo-azougue-100g-p136"
      }}>{`en línea`}</a>{`, pero a precios mucho más altos, por ejemplo,  BRL 850 por 100 g.`}</p>
    <p>{`Por otra parte, en el informe de 2020 `}<a parentName="p" {...{
        "href": "http://www.escolhas.org/wpcontent/uploads/2020/05/TD_04_GARIMPO_A-NOVA-CORRIDA-DO-OURO-NA-AMAZONIA_maio_2020.pdf"
      }}>{`La nueva fiebre del oro en el Amazonas`}</a>{` de la ONG ambientalista brasileña Instituto Escolhas, una fuente pro-garimpeiros de la región brasileña del río Tapajós, alegó que el mercurio tenía un precio de BRL 1.500 y que, por lo tanto, se utilizaba con “moderación”.`}</p>
    <h2>{`Emisiones de mercurio`}</h2>
    <p>{`Las estimaciones de la cantidad de mercurio liberado cada año en el medio ambiente de Brasil varían enormemente.`}</p>
    <p>{`Los grupos garimpeiros insisten en que las emisiones han disminuido en los últimos años debido al aumento del `}<a parentName="p" {...{
        "href": "https://listamercadolivre.com.br/recuperador-de-merc%C3%BArio-cadinho-para-garimpo"
      }}>{`uso de retortas`}</a>{`, un objeto metálico que parece una gran tetera, fácil de adquirir en línea y relativamente económico, que puede recuperar grandes cantidades de mercurio.`}</p>
    <p>{`Los minoristas de retortas afirman que se puede recuperar el 100%, mientras que los estudios sugieren que se puede recuperar más del 70%, a diferencia de los métodos de filtrado estándar que recuperan un 50% en promedio.`}</p>
    <p>{`El estudio más completo de Brasil sobre el uso de mercurio en la minería artesanal, el Inventario Nacional de Emisiones de Mercurio publicado en 2018, concluyó que en 2016 se liberaron en el medio ambiente entre 18,5 y 221,5 toneladas de mercurio, y alrededor de dos tercios de estas van a la atmósfera y el resto al suelo y ríos.`}</p>
    <p>{`La enorme variación depende de la cantidad de oro extraído ilegalmente, que según el informe podría oscilar entre 50% y 100% de la cantidad declarada, aunque, debido a las dificultades de medición, también podría ser mayor.`}</p>
    <p>{`En el primer tercio de 2020, Brasil exportó 29 toneladas de oro, un aumento del 15% con respecto al mismo período de 2019, según señala el informe del Instituto Escolhas.`}</p>
    <blockquote>
      <p parentName="blockquote">{`En el primer tercio de 2020, Brasil exportó 29 toneladas de oro, un aumento del 15% con respecto al mismo período de 2019`}</p>
    </blockquote>
    <hr></hr>
    <p>{`De acuerdo con el Inventario Nacional de Emisiones de Mercurio, la cantidad de mercurio liberada en el medio ambiente también depende de lo común que sea el uso de retortas y técnicas de filtrado.
Las técnicas de filtrado, que pueden recuperar hasta el 50% del mercurio, fueron prácticamente universales entre los 26 grupos de minería artesanal estudiados en el informe, lo que tiene sentido dado el costo de la sustancia, señaló el informe.`}</p>
    <p>{`El informe concluyó que, en promedio, los grupos de minería artesanal de Brasil utilizarían 1 g de mercurio para producir 1 g de oro, aunque el promedio mundial es de 3:1 de mercurio y oro.`}</p>
    <p>{`“Con un kilo de mercurio se puede trabajar durante meses”, dijo una fuente. “Pero con tanta gente trabajando, poco a poco los daños se acumulan”.`}</p>
    <h2>{`Afectaciones a la salud`}</h2>
    <p>{`En una decisión histórica reciente, motivada por la crisis de COVID-19 y a petición de grupos de defensa de los indígenas, la Corte Suprema del Brasil ordenó que las autoridades deben actuar para retirar los miles de garimpeiros de la reserva yanomami, donde al menos cuatro indígenas han muerto a causa de la enfermedad.`}</p>
    <p>{`Sin embargo, aunque todos los mineros se vayan - un resultado bastante ambicioso - y se reduzcan los riesgos de transmisión de COVID-19, otro asesino silencioso permanece, el mercurio.`}</p>
    <p>{`El uso de la sustancia en la minería artesanal puede envenenar los ríos y las cadenas alimenticias locales. En 2019, en una audiencia pública ante la Cámara de Representantes de Brasil, los investigadores presentaron un estudio que relacionaba la parálisis cerebral en niños indígenas de las zonas mineras con la exposición prenatal al mercurio.`}</p>
    <Video url="https://youtube.com/embed/gFpOCFpCn8A" mdxType="Video" />
    <p>{`El mismo año, una investigación de la Fundación Oswaldo Cruz del Brasil determinó que el `}<a parentName="p" {...{
        "href": "https://oglobo.globo.com/brasil/estudo-da-fiocruzmostra-que-56-dos-ianomamis-tem-mercurio-acima-do-limite-23852233"
      }}>{`56% de las mujeres`}</a>{` y los niños indígenas sometidos a pruebas en la reserva indígena de los yanomamis tenían niveles de mercurio que excedían los límites fijados por la Organización Mundial de la Salud.`}</p>
    <p>{`Paulo Cesar Basta, profesor de salud pública a cargo de la investigación, destacó los peligros del mercurio: su longevidad y capacidad de permanencia en el medio ambiente por mucho tiempo, su capacidad para transitar entre aguas, suelos y peces y su capacidad de acumularse y crecer en los seres humanos y otros animales.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"El mercurio en peligro a toda una generación de niños”, afirmó Paulo Cesar Basta, profesor de salud pública`}</p>
    </blockquote>
    <p>{`Actualmente, las tierras indígenas de Roraima enfrentan mayor presión por la minería ilegal y el uso de mercurio, en parte debido a las promesas del presidente Jair Bolsonaro de legalizar la actividad de los garimpeiros, alegan los defensores locales.`}</p>
    <p>{`Las presiones también provienen de las comunidades. El senador de Roraima, Chico Rodrigues, a favor de la minería y aliado de Bolsonaro, grabó recientemente un video sobre la comunidad indígena de Napoleão, alentando la actividad junto con líderes indígenas pro-garimpeiros, entre ellos Irisnaide Silva, quien `}<a parentName="p" {...{
        "href": "https://abracambrasil.org.br/noticias/217-bolsonaro-recebe-indigenas-no-palacio-do-planalto-e-defende-mineracao-e-agropecuaria-em-suas-terras"
      }}>{`se reunió con Bolsonaro`}</a>{` el año pasado en Brasilia, la capital.`}</p>
    <p>{`Al mismo tiempo, en la reserva indígena de Raposa Serra do Sol, la segunda más grande del estado, la reciente invasión de unos 2.000 mineros de oro ilegales y el uso generalizado de mercurio amenazan con envenenar los suministros de agua y las cadenas alimenticias.`}</p>
    <p>{`“Tememos que, con el inicio de la temporada de lluvias, este material (el mercurio) llegue a ríos y arroyos que antes no estaban contaminados”, dijo Ivo Cípio Aureliano, abogado y asesor jurídico del Consejo Indígena de Roraima.`}</p>
    <h2>{`Incautación récord`}</h2>
    <Sidenote side="right" mdxType="Sidenote">
	El Banco Mundial indica que entre 2006 y 2016, Brasil importó un promedio de 25 toneladas anuales y en 2018, importó 20 toneladas.
    </Sidenote>
    <p>{`Brasil sigue importando mercurio para su propia producción industrial, principalmente a Sao Paulo y al a estado meridional de Santa Catarina. El Banco Mundial indica que entre 2006 y 2016, Brasil importó un promedio de 25 toneladas anuales y en 2018, importó 20 toneladas.`}</p>
    <p>{`En 2018, la agencia medioambiental de Brasil, Ibama, incautó un récord de 430 kg de mercurio e interceptó otros 1700 kg que estaban siendo importados legalmente por la empresa química Quimidrol de Santa Catarina desde Turquía, los que Ibama alegó que serían desviados para su uso en pozos mineros ilegales del Amazonas.`}</p>
    <p>{`Durante años, la empresa había importado mercurio que se utilizaba en el mercado de ortodoncia de Brasil, pero cayó en desuso cuando surgieron nuevas tecnologías dentales, afirman los investigadores.`}</p>
    <p>{`Sin embargo, Ibama señala que el mercurio incautado se debía entregar a una empresa fantasma en el estado de Mato Grosso, en la frontera con el Amazonas, la cual recibió entre 2014 y 2017, 8700 kg de mercurio de la empresa química.`}</p>
    <p>{`InfoAmazonia tuvo acceso a los documentos judiciales de la apelación de la empresa, la cual fue rechazada, y estos mencionaban extractos de un informe clasificado de Ibama que señalaba que la empresa fantasma estaba relacionada con un conocido proveedor de mercurio ilícito en la región amazónica del sur de Brasil.`}</p>
    <p>{`“Con la incautación de una cantidad tan grande sería razonable imaginar que habría habido una brecha en el mercado y que las rutas de contrabando habrían aumentado”, dijo un investigador que trabajó en el caso.`}</p>
    <h2>{`Contexto general`}</h2>
    <p>{`Fuentes en Roraima dijeron a InfoAmazonia que la gran mayoría de las personas que introducen mercurio de contrabando en el estado son de poca monta, aunque en última instancia trabajen en nombre de grandes inversores de minería ilegal.`}</p>
    <p>{`Máquinas como las plataformas de dragado que ocupan los ríos del territorio yanomami requieren un costo inicial mínimo de BRL 150.000 y los propietarios también suelen ser dueños de un negocio legítimo, como una gasolinera, un hotel, un supermercado o una tienda de ropa.`}</p>
    <p>{`“Nunca se identificará quién es el propietario”, afirmó una fuente.  “Designará a un minero experimentado como su gerente”, añadió. “Y hará que alguien adquiera el mercurio”.`}</p>
    <p>{`Las plataformas de dragado y otras máquinas utilizadas en la minería ilegal, como las moinhos (trituradoras de rocas) en la reserva de Raposa Serra do Sol, requieren enormes cantidades de gasolina para funcionar constantemente y, en Roraima, es común que los camiones que transportan miles de litros de gasolina sean detenidos y se incauten sus cargas.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Máquinas como las plataformas de dragado que ocupan los ríos del territorio yanomami requieren un costo inicial mínimo de BRL 150.000`}</p>
    </blockquote>
    <p>{`El mercurio y los hombres suelen llegar a los pozos mineros más remotos en avionetas, y las empresas de aviación que operan en Roraima y que han recibido contratos públicos millonarios también han sido `}<a parentName="p" {...{
        "href": "https://oglobo.globo.com/brasilanac-abre-processo-paraapurar-possiveis-irregularidades-de-empresa-de-taxi-aereo-suspeita-de-atuar-em-garimpo-ilegal-1-24530676"
      }}>{`investigadas`}</a>{` por dirigir logística aérea para la minería ilegal.`}</p>
    <p>{`“Sin la logística no se tienen garimpos”, afirmó el señor Marugal, Fiscal Federal.`}</p>
    <p>{`El transporte aéreo en Roraima es un mercado tan lucrativo que se piensa que incluso ha llevado a `}<a parentName="p" {...{
        "href": "http://g1.globo.com/brasil/noticia/2011/05/dono-de-empresa-aerea-e-morto-tiros-em-frente-pizzaria-de-boa-vista.html"
      }}>{`asesinatos`}</a>{` entre `}<a parentName="p" {...{
        "href": "https://mp-rr.jusbrasil.com.br/noticias/100044596/acusado-de-executar-empresario-da-aviacao-e-condenado-a-21-anos"
      }}>{`empresarios rivales`}</a>{` en el pasado.`}</p>
    <p>{`Los helicópteros también se utilizan cada vez más, según una fuente, y se han notificado varias incautaciones recientes tras los esfuerzos de los organismos de orden público por destruir pistas de aterrizaje clandestinas.`}</p>
    <h2>{`Oro venezolano`}</h2>
    <p>{`Y para complicar aún más el asunto para Roraima, el oro de cientos de minas ilegales de Venezuela está siendo traficado con mayor frecuencia a Roraima y luego desde Brasil hacia la cadena de suministro global, según han demostrado las investigaciones de la Policía Federal.`}</p>
    <p>{`El año pasado, según el Comex Stat (estadísticas de comercio exterior) de Brasil, al menos 668 kg de oro, que los agentes federales creían que se habían sacado de Venezuela y de la reserva de los yanomamis, se exportaron oficialmente desde Roraima, a pesar de que el estado no tenía una operación legalizada.`}</p>
    <p>{`El oro fue la segunda mayor exportación del estado en 2019, siendo la India el principal comprador. Ese año, la mayor importación del estado fueron aviones y partes relacionadas, esenciales para la logística del comercio ilegal de la minería de oro.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Para complicar aún más el asunto, el oro de cientos de minas ilegales de Venezuela está siendo traficado con mayor frecuencia a Roraima.`}</p>
    </blockquote>
    <p>{`A principios de este mes, una mujer brasileña fue arrestada en la ciudad fronteriza de Pacaraima con 11 kg de oro y USD 4.200 en efectivo. El mismo día, la Policía Federal hizo una `}<a parentName="p" {...{
        "href": "https://g1.globo.com/rr/roraima/noticia/2020/08/06/pf-deflagra-operacao-contra-comercio-e-contrabando-de-ouro-da-terra-yanomami-e-venezuela.ghtml"
      }}>{`redada en varias direcciones`}</a>{` en Roraima, así como en Sao Paulo, apuntando a una organización criminal responsable de manejar oro tomado ilegalmente de Venezuela y del territorio yanomami.`}</p>
    <p>{`Una de las direcciones es la misma que estuvo en el centro del escándalo que estalló a finales `}<a parentName="p" {...{
        "href": "https://g1.globo.com/rr/roraima/noticia/2019/12/06/pfmira-grupo-que-contrabandeou-12-tonelada-de-ouro-davenezuela-por-rr-e-movimentou-r-230-milhoes.ghtml"
      }}>{`del año pasado`}</a>{`, que involucró a redes criminales brasileñas y venezolanas, con un venezolano buscado por la Interpol.`}</p>
    <p>{`El grupo, según la policía federal, trasladó al menos 1200 kg de oro en un período de dos años. El oro se introdujo de contrabando desde Venezuela a Roraima en pequeñas cantidades, utilizando mulas, y luego se llevó a Sao Paulo, desde donde se exportó al extranjero a la India y los Emiratos Árabes Unidos.`}</p>
    <p>{`“Somos un estado fronterizo, con todas las fragilidades que eso conlleva”, dijo el señor Marugal, Fiscal Federal.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      